import React from 'react'

export function Disclaimer() {
  let today = new Date().toDateString()
  let lastDay = (new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).toDateString()
  return (
    <p className="text-muted mt-4" style={{ fontSize: '14px' }}>Quote issued on {today} <br /> Valid until {lastDay}</p>
  )
}

export default Disclaimer