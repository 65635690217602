import React, { useEffect } from 'react'
import { Field } from 'formik'
import { Slider, styled } from '@mui/material';
import { lenders } from './utils/lenders';
import { MAX_DOWNPAYMENT, MIN_DOWNPAYMENT, DEFAULT_DOWN_PAYMENT, SLIDER_STEP } from './utils/constants';

const USCurrentStyle = amount => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(amount)

const DownPaymentSlider = styled(Slider)({
  color: 'var(--dark-blue)',
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: 'var(--primary-off-white)',
    border: '2px solid var(--primary-blue)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: '.9rem',
    background: 'unset',
    padding: 0,
    width: 70,
    height: 30,
    color: 'var(--primary-blue)',
    backgroundColor: 'var(--primary-off-white)',
  },
});

const marks = [
  {
    value: 0,
    label: '$0'
  },
  // {
  //   value: 2500,
  //   label: '$2.5k'
  // },
  {
    value: 5000,
    label: '$5k'
  },
  // {
  //   value: 7500,
  //   label: '$7.5k'
  // },
  {
    value: 10000,
    label: '$10k'
  },
  // {
  //   value: 12500,
  //   label: '$12.5k'
  // },
  {
    value: 15000,
    label: '$15k'
  },
];

function labelText(val) {
  return USCurrentStyle(val);
}

export function RangeSliderInput(props) {
  const { name, formik } = props

  useEffect(() => {
    let lenderDownPayement = lenders.find(lender => lender.name === formik.values.lender).downpayment
    if (lenderDownPayement > formik.values.downpayment) {
      formik.setFieldValue('downpayment', lenderDownPayement > 0 ? lenderDownPayement : DEFAULT_DOWN_PAYMENT)
    }
  }, [formik.values.lender])

  const onChangeHandler = e => {
    let discount = formik.values.discount > 0 ? formik.values.discount : 0
    let fullPrice = formik.values.full_price
    let todaysPrice = fullPrice - discount
    let lenderDownPayement = lenders.find(lender => lender.name === formik.values.lender).downpayment
    if (e.target.value >= lenderDownPayement && e.target.value <= todaysPrice) {
      formik.setFieldValue(name, e.target.value)
    } else return
  }

  return (
    <Field
      component={DownPaymentSlider}
      name={name}
      size="small"
      defaultValue={DEFAULT_DOWN_PAYMENT}
      valueLabelFormat={labelText}
      value={formik.values[name]}
      aria-labelledby="discrete-slider-restrict"
      step={SLIDER_STEP}
      valueLabelDisplay="on"
      marks={marks}
      min={MIN_DOWNPAYMENT}
      max={MAX_DOWNPAYMENT}
      onChange={onChangeHandler}
    />
  )
}

export default RangeSliderInput