import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

export function FormikText(props) {
  const { label, name, optional, ...rest } = props
  return (
    <div className="row justify-content-center mb-3">
      <div className="col-5">
        <label className="">{label}</label>
      </div>
      <div className="col-7">
        <Field name={name}>
          {
            props => {
              const { field, meta } = props
              return (
                <input type="number" min={1} className={`form-control rounded-0 ps-4 ${meta.error && meta.touched ? 'border-error' : ''}`} id={name} {...field} {...rest} aria-label={name} aria-describedby={name + '-addon'} />
              )
            }
          }
        </Field>
        <ErrorMessage name={name} component={TextError} />
      </div>
    </div>
  )
}

export default FormikText