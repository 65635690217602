import { useRef, useState } from 'react';
import Header from './components/Header'
import Calculator from './components/Calculator';
import Footer from './components/Footer';
import './App.css';

function App() {
  const [clearAll, setClearAll] = useState(false)
  const resetForm = () => setClearAll(!clearAll)
  const printRef = useRef(null)

  return (
    <section id="athens-quote-calc" className="container-xl athens-bg-off-white">
      <Header {...{ resetForm, printRef }} />
      <div className="row" ref={printRef}>
        <Calculator clearAll={clearAll} />
      </div>
      <Footer />
    </section>
  );
}

export default App;
