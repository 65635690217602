import React, { useEffect, useRef } from 'react'
import { Formik, Form } from 'formik'
import { FaPlus, FaMinus } from 'react-icons/fa'
import RangeSliderInput from './RangeSliderInput'
import { initialValues } from './utils/initialValues'
import { validationSchema } from './utils/validationsSchema'
import { lenders } from './utils/lenders'
import FormikText from './FormikText'
import FormikSelect from './FormikSelect'
import MonthlyCost from './MonthlyCost'
import TotalCost from './TotalCost'
import Disclaimer from './Disclaimer'
import { MAX_DOWNPAYMENT, MIN_DOWNPAYMENT } from './utils/constants'
import DarkLogo from '../assets/AthensLogo-BlueVerticalStack.png'

export function Calculator({ clearAll }) {
  const formikRef = useRef()

  useEffect(() => {
    formikRef.current.resetForm()
  }, [clearAll])

  const onPaymentBtnClick = (formik, type) => {
    let halfStep = 250
    let discount = formik.values.discount > 0 ? formik.values.discount : 0
    let fullPrice = formik.values.full_price
    let todaysPrice = fullPrice - discount
    let lenderDownPayement = lenders.find(lender => lender.name === formik.values.lender).downpayment
    if (type === 'plus' && formik.values.downpayment >= lenderDownPayement && formik.values.downpayment < MAX_DOWNPAYMENT && (formik.values.downpayment + halfStep) <= todaysPrice) {
      formik.setFieldValue('downpayment', formik.values.downpayment + halfStep)
    } else if (type === 'minus' && formik.values.downpayment >= (lenderDownPayement + halfStep) && formik.values.downpayment > MIN_DOWNPAYMENT) {
      formik.setFieldValue('downpayment', formik.values.downpayment - halfStep)
    } else return
  }

  return (
    <div className="col">
      <div className="bg-white py-3 p-sm-4 my-4 mx-sm-3">
        <div className="container-xl">
          <center className="print">
            <figure className="mb-0">
              <img src={DarkLogo} alt="Athens Logo" style={{ maxHeight: '60px' }} />
            </figure>
          </center>
          <div className="row justify-content-center mt-4 mb-5">
            <div className="col">
              <h1 className="text-center athens-text-primary fw-bold">Payment Calculator</h1>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formikRef}
          >
            {
              formik => {
                return (
                  <Form id="calc-form" className="row gx-xl-5">
                    <div className="col-md-7">
                      <FormikText name="full_price" label="Full Price" placeholder="0" />
                      <FormikText name="discount" label="Discount" placeholder="0" />
                      <FormikSelect name="lender" label="Lender" options={lenders} />
                      <div className="row gx-3 gx-md-1 mt-4">
                        <div className="col-12">
                          <label className="d-block mb-4 pb-3">Down Payment</label>
                        </div>
                        <div className="col-auto">
                          <button type='button' className="payment-btn" onClick={() => onPaymentBtnClick(formik, 'minus')}><FaMinus /></button>
                        </div>
                        <div className="col slider-pt">
                          <RangeSliderInput name="downpayment" formik={formik} />
                        </div>
                        <div className="col-auto">
                          <button type='button' className="payment-btn" onClick={() => onPaymentBtnClick(formik, 'plus')}><FaPlus /></button>
                        </div>
                      </div>
                      <div className="d-none d-md-block me-lg-5 pe-lg-5">
                        <Disclaimer />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div id="calc-outcome" className="text-center my-4 mt-md-0 mx-auto py-4 py-xl-5 px-2 athens-text-primary athens-bg-off-white">
                        <div className="mb-2">
                          {
                            formik.values.full_price >= 1000 && formik.values.lender !== lenders[0].name ?
                              <MonthlyCost formik={formik} />
                              :
                              <div className="d-inline">
                                <span className="d-inline-block mt-md-1 display-6">$</span>
                                <span className="display-4 fw-bold">_ _ _</span>
                              </div>
                          }
                        </div>
                        {
                          formik.values.lender !== lenders[0].name ?
                            <p className="mt-3">Per Month For <strong>{lenders.find(lender => lender.name === formik.values.lender).financePlan}</strong> Months</p>
                            :
                            <p className="mt-3">No Lender Selected</p>
                        }
                        <TotalCost formik={formik} />
                      </div>
                      <div className="d-md-none">
                        <Disclaimer />
                      </div>
                    </div>
                  </Form>
                )
              }
            }
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Calculator