import React from 'react'
import { numberFormat } from './utils/numberFormat'

export function TotalCost({ formik }) {
  let discount = formik.values.discount > 0 ? formik.values.discount : 0
  let fullPrice = formik.values.full_price
  let todaysPrice = fullPrice - discount

  return (
    <div className="text-center">
      <span className="me-2">Today's Price</span>
      {
        fullPrice >= 1000 ?
          <>
            { // With discount
              formik.values.discount > 0 ?
                <div className="d-inline">
                  <span className="text-decoration-line-through me-2">${numberFormat(fullPrice)}</span>
                  <div className="d-inline">
                    <strong className={`d-inline-block mt-md-1 ${todaysPrice < 0 ? 'text-danger' : ''}`}>$</strong>
                    <strong className={`${todaysPrice < 0 ? 'text-danger' : ''}`}>{numberFormat(todaysPrice)}</strong>
                  </div>
                </div>
                : // No discount
                <div className="d-inline">
                  <strong>${numberFormat(fullPrice)}</strong>
                </div>
            }
          </>
          :
          <div className="d-inline">
            <small>$</small>
            <span>_ _ _</span>
          </div>
      }
    </div>
  )
}

export default TotalCost