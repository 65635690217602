export const lenders = [
  {
    name: 'Choose Your Lender',
    minRate: 0,
    maxRate: 0,
    financePlan: 0,
    downpayment: 0
  },
  {
    name: 'Ally',
    minRate: 0,
    maxRate: 0,
    financePlan: 36,
    downpayment: 0
  },
  {
    name: 'Athens Hair',
    minRate: 0,
    maxRate: 0,
    financePlan: 24,
    downpayment: 0
  },
]