import React from 'react'
import { lenders } from './utils/lenders'
import { numberFormat } from './utils/numberFormat'
import { calcAPRMonthlyPayment } from './utils/calcAPRMonthlyPayment'

export function MonthlyCost({ formik }) {
  let discount = formik.values.discount === '' ? 0 : formik.values.discount
  let cost = (formik.values.full_price - (discount + formik.values.downpayment))
  let lender = lenders.find(lender => lender.name === formik.values.lender)
  if (lender.minRate === lender.maxRate) {
    cost = cost / lender.financePlan
    return (
      <div>
        <span className={`d-inline-block align-top mt-md-1 fs-3 fw-bold ${cost >= 0 ? '' : 'text-danger'}`}>$</span>
        <span className={`display-4 fw-bold ${cost >= 0 ? '' : 'text-danger'}`}>{numberFormat(cost)}</span>
      </div>
    )
  } else {
    let minCost = calcAPRMonthlyPayment(cost, lender.minRate, lender.financePlan)
    let maxCost = calcAPRMonthlyPayment(cost, lender.maxRate, lender.financePlan)
    return (
      <div id="cost-range-wrapper" className={`${(minCost < 0 || maxCost < 0) ? 'text-danger' : ''}`}>
        <sup className="fs-5 fw-bold">$</sup>
        <span className="cost-range fw-bold">{numberFormat(minCost)} -</span>
        <sup className="fs-5 ms-2 fw-bold">$</sup>
        <span className="cost-range fw-bold">{numberFormat(maxCost)}</span>
      </div>
    )
  }
}

export default MonthlyCost