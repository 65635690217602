import React from 'react'
import Logo from '../assets/AthensLogo-WhiteWide.avif'
import ReactToPrint from 'react-to-print'

export function Header({ resetForm, printRef }) {
  return (
    <header className="row justify-content-center justify-content-sm-between gy-4 gy-sm-0 athens-bg-dark py-3">
      <div className="col-auto">
        <figure className="mb-0 px-sm-3 h-100 d-flex align-items-center">
          <img src={Logo} alt="Athens Logo" className="logo" />
        </figure>
      </div>
      <div className="col-auto">
        <ReactToPrint
          trigger={() => <button type='button' className="clear-all athens-bg-off-white rounded-0 fw-bold me-3 border-0">Print</button>}
          content={() => printRef.current}
        />
        <button type="button" className="clear-all athens-bg-off-white rounded-0 fw-bold  me-3 border-0" onClick={resetForm}>Clear All</button>

      </div>
    </header>
  )
}

export default Header