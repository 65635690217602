import React from 'react'

export function TextError({ children }) {
  return (
    <div className="text-error">
      {children}
    </div>
  )
}

export default TextError