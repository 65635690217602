import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

export function FormikSelect(props) {
  const { name, label, options, ...rest } = props
  return (
    <div className="row justify-content-center mb-3">
      <div className="col-5">
        <label className="">{label}</label>
      </div>
      <div className="col-7">
        <Field as="select" id={name} name={name} {...rest} className="form-select rounded-0">
          {
            options.map((item, x) => (
              <option key={x} value={item.name}>
                {item.name}
              </option>
            ))
          }
        </Field>
        <ErrorMessage name={name} component={TextError} />
      </div>
    </div>
  )
}

export default FormikSelect